<template>
    <div v-loading.fullscreen.lock="fullscreenLoading">
        <div class="box">
            <Title title="草稿箱" />
            <div class="ct_box">
                <div class="search-status_box flex">
                    <div class="search_box flex">
                        <div class="order_search flex">
                            <img src="@/assets/user/setMenu/search.png" alt="" class="search_icon" />
                            <el-input class="order_search_inp" v-model="title" placeholder="搜索草稿标题"></el-input>
                            <button class="search_but" @click="listFn">搜索</button>
                        </div>
                        <el-select v-model="resource.title" @change="ClassNum" placeholder="请选择媒体类别">
                            <el-option v-for="item in resourceList" :key="item.value" :value="item.title">
                            </el-option>
                        </el-select>
                    </div>
                    <!-- <ul class="status_ul2 flex">
                        <li :class="status == item ? 'active' : ''" v-for="(item, index) in statusUl" :key="index"
                            @click="statusShow(item)">
                            {{ item }}
                        </li>
                    </ul> -->
                </div>
                <table class="table">
                    <thead>
                        <tr>
                            <td>类型</td>
                            <td>标题</td>
                            <td>创建时间</td>
                            <td>操作</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in tableData" :key="index">
                            <td>{{ item.category_id == 0 ? packageList[item.category_id + 1] :
        resourceList[item.category_id -
            1].title }}</td>
                            <td>{{ item.title }}</td>
                            <td>{{ item.updatetime }}</td>
                            <td style="width:8rem;">
                                <div class="caozuo_but flex">
                                    <p class="p1" @click="goFill(item)">去发布</p>
                                    <p class="p2" @click.stop="deleteFn(item)">删除</p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="flex" style="justify-content: flex-end">
                    <el-pagination background layout="prev, pager, next" :total="total_page" hide-on-single-page>
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Title from '../../components/UserInfoTitle.vue'
import { timeCut } from '../../util/timeCut'
export default {
    components: {
        Title,
    },
    mounted() {
        console.log(this.$route)
        this.listFn()
        this.$store.commit('childTab', '草稿箱')
    },
    data() {
        return {
            resource: {
                id: 0,
                title: '不限'
            },
            resourceList: [],
            packageList: [],
            time: '',
            title: '',
            fullscreenLoading: false,
            page: 1,
            tableData: [],
            total_page: 0, //分页总页数
            // statusUl: ['全部', '待审核', '开票中', '已完成', '被拒单'],
            // status: '全部',
        }
    },
    methods: {
        statusShow(item) {
            this.status = item
        },
        LinkShow() {
            this.Link = true
        },
        wordOrderShow() {
            this.WorkOrder = true
        },
        goFill(item) {
            this.$router.push({
                path: '/user/Orfill',
                query: {
                    itemId: item.id,
                    id: item.category_id
                }
            })
        },
        listFn() {
            this.fullscreenLoading = true,
                // 取媒体类别
                this.curlGet('/api/medium_category/list').then(res => {
                    if (res.data.code) {
                        // console.log(this.resourceList,'nav');
                        this.resourceList = res.data.data

                    }
                })
            this.curlGet('/api/package/list').then(res => {
                if (res.data.code) {
                    this.packageList = res.data.data
                    console.log(this.packageList);
                }
            })
            let data = {}
            if (this.title !== '') {
                data.title = this.title
            }
            if (this.resource.id) {
                data.category_id = this.resource.id
            }
            this.curlPost('/api/users/draft', data).then(res => {
                if (res.data) {
                    this.tableData = res.data.data.list
                    this.total_page = res.data.data.total_page
                    this.tableData.forEach((item, index) => {
                        item.updatetime = timeCut(item.updatetime * 1000)
                    })
                }
                this.fullscreenLoading = false
            })
        },
        // 删除
        deleteFn(item) {
            console.log(item);
            this.$confirm('是否删除此草稿?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                this.curlPost('/api/users/draft/del', {
                    id: item.id
                }).then(res => {
                    console.log(res);
                    if (res.data.code) {
                        this.listFn()
                        return this.$message({
                            message: '删除成功',
                            type: 'success',
                        })
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });

        },
        ClassNum(val) {
            let resultArr = this.resourceList.filter((item) => {
                return item.title === val
            });
            console.log(resultArr);
            this.resource = resultArr[0]
            this.listFn()
        },
        // 切换页
        currentTab(val) {
            console.log('切换');
            this.page = val
            this.listFn()
        },
        // 上一页
        prevClick() {
            console.log('up');
            this.page -= 1
            this.listFn()
        },
        // 下一页
        // next-click
        nextClick() {
            console.log('down');
            this.page += 1
            this.listFn()
        },
    },
}
</script>

<style lang="scss" scoped>
@import '../../scss/Invoice.scss';

.caozuo_but {
    justify-content: space-between;

    .p1 {
        color: #108CDD;
        font-size: 1.3rem;
        cursor: pointer;
    }

    .p2 {
        color: #FF0011;
        font-size: 1.3rem;
        cursor: pointer;
    }
}
</style>